import { defined, notNull, withEffect } from './common';
export const storage = builder(window.localStorage);
export const tempStorage = builder(window.sessionStorage);
export function storedProp(key, defaultValue, fromStr, toStr) {
    const compatKey = 'analyse.' + key;
    let cached;
    return function (replacement) {
        if (defined(replacement) && replacement != cached) {
            cached = replacement;
            storage.set(key, toStr(replacement));
        }
        else if (!defined(cached)) {
            const compatValue = storage.get(compatKey);
            if (notNull(compatValue)) {
                storage.set(key, compatValue);
                storage.remove(compatKey);
            }
            const str = storage.get(key);
            cached = str === null ? defaultValue : fromStr(str);
        }
        return cached;
    };
}
export const storedStringProp = (k, defaultValue) => storedProp(k, defaultValue, str => str, v => v);
export const storedBooleanProp = (k, defaultValue) => storedProp(k, defaultValue, str => str === 'true', v => v.toString());
export const storedStringPropWithEffect = (k, defaultValue, effect) => withEffect(storedStringProp(k, defaultValue), effect);
export const storedBooleanPropWithEffect = (k, defaultValue, effect) => withEffect(storedBooleanProp(k, defaultValue), effect);
export const storedIntProp = (k, defaultValue) => storedProp(k, defaultValue, str => Number(str), v => v + '');
export const storedIntPropWithEffect = (k, defaultValue, effect) => withEffect(storedIntProp(k, defaultValue), effect);
export const storedJsonProp = (key, defaultValue) => (v) => {
    if (defined(v)) {
        storage.set(key, JSON.stringify(v));
        return v;
    }
    const ret = JSON.parse(storage.get(key));
    return ret !== null ? ret : defaultValue();
};
export const storedMap = (propKey, maxSize, defaultValue) => {
    const prop = storedJsonProp(propKey, () => []);
    const map = new Map(prop());
    return (key, v) => {
        if (defined(v)) {
            map.delete(key); // update insertion order as old entries are culled
            map.set(key, v);
            prop(Array.from(map.entries()).slice(-maxSize));
        }
        const ret = map.get(key);
        return defined(ret) ? ret : defaultValue();
    };
};
export const storedSet = (propKey, maxSize) => {
    const prop = storedJsonProp(propKey, () => []);
    let set = new Set(prop());
    return (v) => {
        if (defined(v)) {
            set.add(v);
            set = new Set([...set].slice(-maxSize)); // sets maintain insertion order
            prop([...set]);
        }
        return set;
    };
};
export const toggleWithUsed = (key, toggle) => {
    let value = toggle();
    let used = !!storage.get(key);
    const novTog = (v) => {
        if (defined(v)) {
            value = v;
            if (!used) {
                storage.set(key, '1');
                used = true;
            }
            toggle.effect(v);
        }
        return value;
    };
    novTog.toggle = () => novTog(!novTog());
    novTog.used = () => used;
    novTog.effect = toggle.effect;
    return novTog;
};
export function once(key, mod) {
    if (mod === 'always')
        return true;
    if (storage.get(key))
        return false;
    storage.set(key, '1');
    return true;
}
function builder(storage) {
    const api = {
        get: (k) => storage.getItem(k),
        set: (k, v) => storage.setItem(k, v),
        fire: (k, v) => storage.setItem(k, JSON.stringify({
            sri: site.sri,
            nonce: Math.random(), // ensure item changes
            value: v,
        })),
        remove: (k) => storage.removeItem(k),
        make: (k, ttl) => {
            const bdKey = ttl && `${k}--bd`;
            const remove = () => {
                api.remove(k);
                if (bdKey)
                    api.remove(bdKey);
            };
            return {
                get: () => {
                    if (!bdKey)
                        return api.get(k);
                    const birthday = Number(api.get(bdKey));
                    if (!birthday)
                        api.set(bdKey, String(Date.now()));
                    else if (Date.now() - birthday > ttl)
                        remove();
                    return api.get(k);
                },
                set: (v) => {
                    api.set(k, v);
                    if (bdKey)
                        api.set(bdKey, String(Date.now()));
                },
                fire: (v) => api.fire(k, v),
                remove,
                listen: (f) => window.addEventListener('storage', e => {
                    if (e.key !== k || e.storageArea !== storage || e.newValue === null)
                        return;
                    let parsed;
                    try {
                        parsed = JSON.parse(e.newValue);
                    }
                    catch (_) {
                        return;
                    }
                    // check sri, because Safari fires events also in the original
                    // document when there are multiple tabs
                    if (parsed?.sri && parsed.sri !== site.sri)
                        f(parsed);
                }),
            };
        },
        boolean: (k) => ({
            get: () => api.get(k) == '1',
            getOrDefault: (defaultValue) => {
                const stored = api.get(k);
                return stored === null ? defaultValue : stored == '1';
            },
            set: (v) => api.set(k, v ? '1' : '0'),
            toggle: () => api.set(k, api.get(k) == '1' ? '0' : '1'),
        }),
    };
    return api;
}
